import React, {
  useEffect,
  useState,
  useRef,
  useImperativeHandle,
  forwardRef,
} from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  Typography,
  TableHead,
  TableRow,
  Paper,
  TablePagination,
  CircularProgress,
  IconButton,
  Menu,
  MenuItem,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import styled from "styled-components";
import axios from "axios";
import api from "../../api/config";
import {
  GetPatientMetaData,
  GetPatientStudyMeta,
  GetPatientStudyList,
} from "../../api/Patient";
import { DICOMProxyToken, ViewerPath } from "../../api/Authentication";
import {
  downloadZippedStudy,
  cancelDownload,
  resetCancelDownload,
} from "../../services/downloadService";
import {
  MoreVert,
  KeyboardArrowDown,
  KeyboardArrowRight,
} from "@mui/icons-material";
import DownloadProgressModal from "../shared/DownloadProgressModal";
import LogsModal from "../shared/LogsModal";
import ShareStudyModal from "../shared/ShareStudyModal";
import SendStudyRecordModal from "../shared/SendStudyRecordModal";

const InfoTable = styled.div`
  background-color: #2b3342;
  padding: 16px;
  border-radius: 8px;
  box-shadow: 0px 4px 6px 0px #00000040;
  flex: ${({ flex }) => flex || 1};
  min-height: 0;
  overflow-y: hidden;
`;

const StyledTableContainer = styled(TableContainer)`
  box-shadow: none !important;
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  background-color: transparent !important;
  flex-grow: 1;
`;

const StyledPaper = styled(Paper)`
  background-color: transparent !important;
  color: #fbfeff !important;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const StyledTableRow = styled(TableRow)`
  td {
    padding: 5px;
  }
  &:hover {
    background-color: #5a6285 !important;
  }
  &.selected-row td {
    background-color: #a5e0ff !important;
    color: #000000 !important;
  }
`;

const ExpandedTableRow = styled(TableRow)`
  td {
    background-color: #3b4252 !important;
    border: 2px solid #a5e0ff;
    border-radius: 0 0 8px 8px;
  }
`;

const TableWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow-y: auto;
`;

const ScrollableTableBody = styled(TableBody)`
  overflow-y: auto;
  flex-grow: 1;
  &::-webkit-scrollbar {
    width: 5px;
  }
  &::-webkit-scrollbar-track {
    background: #28929c;
    opacity: 0.6;
    border-radius: 100px;
  }
  &::-webkit-scrollbar-thumb {
    background: #3e526a;
    border-radius: 100px;
  }
`;

const ThumbnailList = styled.div`
  display: flex;
`;

const ThumbnailListItem = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 8px;
  cursor: pointer;
  & img {
    margin-right: 10px;
  }
`;

const OuterContainer = styled.div`
  max-width: 100%;
  overflow-x: auto;
  white-space: nowrap;
  &::-webkit-scrollbar {
    height: 8px;
  }
  &::-webkit-scrollbar-track {
    background: #28929c;
    border-radius: 100px;
  }
  &::-webkit-scrollbar-thumb {
    background: #28929c;
    border: 1px solid #3e526a;
    border-radius: 100px;
  }
`;

const useStyles = makeStyles({
  headerCell: {
    backgroundColor: "#3E526A !important",
    color: "#A5E0FF !important",
    textTransform: "capitalize",
    fontWeight: 600,
    fontSize: "14px",
  },
  tableRow: {
    "& .MuiTableCell-head": {
      backgroundColor: "#3E526A !important",
      color: "#A5E0FF !important",
      textTransform: "capitalize",
      fontWeight: 600,
      fontSize: "14px",
    },
  },
});

const columns = [
  { id: "StudyDescription", label: "Study Description" },
  { id: "Modality", label: "Modality" },
  { id: "StudyDate", label: "Study Date" },
  { id: "TotalInstancesInStudy", label: "Total Instances" },
];

const PatientStudies = forwardRef(({ patient }, ref) => {
  const classes = useStyles();
  const rowRef = useRef(null);
  const [studies, setStudies] = useState(null);
  const [patientStudyMeta, setPatientStudyMeta] = useState({});
  const [thumbnails, setThumbnails] = useState({});
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalRows, setTotalRows] = useState(null);
  const [expandedRows, setExpandedRows] = useState([]);
  const [loadingDownloadModal, setLoadingDownloadModal] = useState(false);
  const [isShareStudyModalOpen, setIsShareStudyModalOpen] = useState(false);
  const [isSendStudyRecordModalOpen, setisSendStudyRecordModalOpen] =
    useState(false);
  const [isLogsModalOpen, setIsLogsModalOpen] = useState(false);
  const [downloadMessage, setDownloadMessage] = useState(
    "Starting download..."
  );
  const [downloadProgress, setDownloadProgress] = useState(0);
  const [selectedStudy, setSelectedStudy] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedStudyForMenu, setSelectedStudyForMenu] = useState(null);
  const [loadingThumbnails, setLoadingThumbnails] = useState({});
  const [downloadQueue, setDownloadQueue] = useState([]);
  const [isDownloadModalOpen, setIsDownloadModalOpen] = useState(false);
  const isCancelledRef = useRef(false);

  useEffect(() => {
    if (patient) {
      const fetchPatientMetaData = async () => {
        setStudies(null);
        setPatientStudyMeta({});
        setExpandedRows([]);
        setTotalRows(null);
        setThumbnails({});
        try {
          const studyListRequest = await api.get(
            `${GetPatientStudyList}/${patient.uuid}`
          );
          setStudies(studyListRequest.data);
          setTotalRows(studyListRequest.data.length);
          const patientMetaDataRequest = await api.get(
            `${GetPatientMetaData}/${patient.uuid}`
          );
          setStudies(patientMetaDataRequest.data);
          setTotalRows(patientMetaDataRequest.data.length);
        } catch (error) {
          console.error("Failed to fetch patient meta data", error);
          setStudies([]);
          setTotalRows(0);
        }
      };

      fetchPatientMetaData();
    }
  }, [patient]);

  const fetchPatientStudyMeta = async (studyId) => {
    try {
      const response = await api.get(`${GetPatientStudyMeta}/${studyId}`);
      const studyMeta = response.data[0];
      setPatientStudyMeta((prevState) => ({
        ...prevState,
        [studyId]: studyMeta,
      }));

      const tokenResponse = await api.get(DICOMProxyToken);
      const token = tokenResponse.data.token;

      for (let series of studyMeta.Series) {
        const instance = series.Instances[0];
        if (instance) {
          const thumbnailUrl = await getThumbnailUrl(
            studyMeta.StudyInstanceUID,
            series.SeriesInstanceUID,
            instance.SOPInstanceUID,
            token
          );
          if (thumbnailUrl) {
            setThumbnails((prevThumbnails) => ({
              ...prevThumbnails,
              [studyId]: {
                ...(prevThumbnails[studyId] || {}),
                [series.SeriesInstanceUID]: thumbnailUrl,
              },
            }));
          }
        }
      }
    } catch (error) {
      console.error("Failed to fetch patient study meta", error);
    }
  };

  const getThumbnailUrl = async (
    studyInstanceUID,
    seriesInstanceUID,
    sopInstanceUID,
    dicomToken
  ) => {
    try {
      const url = `https://healthcare.googleapis.com/v1/projects/nexusmd-prod/locations/us/datasets/dicom.nexus-md.factstore/dicomStores/production_phi/dicomWeb/studies/${studyInstanceUID}/series/${seriesInstanceUID}/instances/${sopInstanceUID}/rendered`;

      const response = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${dicomToken}`,
          Accept: "image/jpeg",
        },
        responseType: "blob",
      });

      if (response.status === 200) {
        const imageObjectURL = URL.createObjectURL(response.data);
        return imageObjectURL;
      } else {
        console.error("Non-200 status code:", response.status);
        return null;
      }
    } catch (error) {
      if (error.response && error.response.status === 400) {
        console.error(
          "Failed to fetch thumbnail URL with 400 error:",
          error.response.data
        );
      } else {
        console.error("Failed to fetch thumbnail URL:", error.message);
      }
      return null;
    }
  };

  const toggleRowExpansion = (studyId) => {
    if (!expandedRows.includes(studyId)) {
      setLoadingThumbnails((prev) => ({ ...prev, [studyId]: true }));
      fetchPatientStudyMeta(studyId);
    }
    setExpandedRows((prevExpandedRows) =>
      prevExpandedRows.includes(studyId)
        ? prevExpandedRows.filter((id) => id !== studyId)
        : [...prevExpandedRows, studyId]
    );
  };

  const handleMenuOpen = (event, study) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
    setSelectedStudyForMenu(study);
  };

  const handleMenuClose = (event) => {
    event.stopPropagation();
    setAnchorEl(null);
    setSelectedStudyForMenu(null);
  };

  const handleThumbnailClick = async (
    studyInstanceUID,
    study,
    seriesInstanceUID,
    sopInstanceUID
  ) => {
    const { StudyInstanceUID, Modality } = study;
    try {
      const tokenResponse = await api.get(DICOMProxyToken);
      const dicomToken = tokenResponse.data.token;
      const nexusToken = localStorage.getItem("token");
      const selectedPatientId = patient.uuid;
      window.open(
        `${ViewerPath}?StudyInstanceUIDs=${StudyInstanceUID}&initialSeriesInstanceUID=${seriesInstanceUID}&token=${dicomToken}&nexusToken=${nexusToken}&patientId=${selectedPatientId}&origin=movu&modality=${Modality}`
      );
    } catch (error) {
      console.error("Error processing DICOM file:", error);
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleDownloadStudyClick = async (study) => {
    try {
      setLoadingDownloadModal(true);
      resetCancelDownload();

      setDownloadMessage("Downloading...");
      setDownloadProgress(0);

      const tokenResponse = await api.get(DICOMProxyToken);
      const dicomToken = tokenResponse.data.token;
      const studyUrl = `https://healthcare.googleapis.com/v1beta1/projects/nexusmd-prod/locations/us/datasets/dicom.nexus-md.factstore/dicomStores/production_phi/dicomWeb/studies/${study.StudyInstanceUID}`;

      const interval = setInterval(() => {
        setDownloadProgress((prevProgress) => {
          const newProgress = prevProgress + 2;
          if (newProgress >= 90) {
            clearInterval(interval);
            return 90;
          }
          return newProgress;
        });
      }, 1000);

      await downloadZippedStudy(
        studyUrl,
        { Authorization: `Bearer ${dicomToken}` },
        `${study.StudyDate}_${study.Modality}_${study.StudyDescription}_${patient.first_name}${patient.last_name}.zip`,
        () => {
          clearInterval(interval);
          setDownloadProgress(100);
          setDownloadMessage("Download completed!");
        }
      );
    } catch (error) {
      if (error.name === "AbortError") {
        console.log("Download cancelled");
      } else {
        console.error("Error downloading study:", error);
        setDownloadMessage("Failed to download study.");
      }
    } finally {
      setLoadingDownloadModal(false);
    }
  };

  const stopDownload = () => {
    cancelDownload();
    isCancelledRef.current = true;
    setDownloadQueue([]);
    setLoadingDownloadModal(false);
    setIsDownloadModalOpen(false);
  };

  const handleSendStudyRecordsClick = (studyId) => {
    setSelectedStudy(studyId);
    setisSendStudyRecordModalOpen(true);
    console.log("Send study records clicked");
  };

  const handleShareStudyClick = (studyId) => {
    setSelectedStudy(studyId);
    setIsShareStudyModalOpen(true);
  };

  const handleLogsClick = (studyId) => {
    setSelectedStudy(studyId);
    setIsLogsModalOpen(true);
  };

  useImperativeHandle(ref, () => ({
    downloadAll() {
      handleDownloadAll();
    },
  }));

  const handleDownloadAll = async (study = null) => {
    let studiesToDownload = study ? [study] : studies;

    if (!studiesToDownload || studiesToDownload.length === 0) return;

    isCancelledRef.current = false;

    setDownloadQueue([]);

    setIsDownloadModalOpen(true);
    isCancelledRef.current = false;

    const initialQueue = studiesToDownload.map((s) => ({
      id: s.StudyInstanceUID,
      description: s.StudyDescription || "Unnamed Study",
      status: "pending",
    }));

    setDownloadQueue(initialQueue);

    for (let i = 0; i < studiesToDownload.length; i++) {
      if (isCancelledRef.current) {
        console.log("DownloadAll: Cancelled by user");
        break;
      }

      const s = studiesToDownload[i];

      setDownloadQueue((prev) =>
        prev.map((item) =>
          item.id === s.StudyInstanceUID
            ? { ...item, status: "downloading" }
            : item
        )
      );

      try {
        await handleDownloadStudyClick(s);

        if (!isCancelledRef.current) {
          setDownloadQueue((prev) =>
            prev.map((item) =>
              item.id === s.StudyInstanceUID
                ? { ...item, status: "success" }
                : item
            )
          );
        }
      } catch (error) {
        if (!isCancelledRef.current) {
          setDownloadQueue((prev) =>
            prev.map((item) =>
              item.id === s.StudyInstanceUID
                ? { ...item, status: "failed" }
                : item
            )
          );
        }
      }
    }

    if (!isCancelledRef.current) {
      setTimeout(() => {
        setIsDownloadModalOpen(false);
      }, 2000);
    }
  };

  return (
    <div style={{ marginTop: "16px" }}>
      <InfoTable>
        <Typography style={{ marginBottom: "10px" }}>
          Patient Studies ({totalRows !== null ? totalRows : "Loading..."})
        </Typography>
        <StyledTableContainer component={StyledPaper}>
          {studies === null ? (
            <div className="loadingContainer">
              <CircularProgress style={{ color: "#FBFEFF" }} />
            </div>
          ) : (
            <TableWrapper>
              <Table stickyHeader>
                <TableHead>
                  <TableRow className={classes.tableRow}>
                    <TableCell className={classes.headerCell}></TableCell>
                    {columns.map((column) => (
                      <TableCell key={column.id} className={classes.headerCell}>
                        {column.label}
                      </TableCell>
                    ))}
                    <TableCell className={classes.headerCell}></TableCell>
                  </TableRow>
                </TableHead>
                <ScrollableTableBody>
                  {studies.length === 0 ? (
                    <TableRow style={{ height: "100%" }}>
                      <TableCell
                        colSpan={columns.length + 2}
                        style={{ textAlign: "center", height: "100%" }}
                      >
                        No Studies Available
                      </TableCell>
                    </TableRow>
                  ) : (
                    studies.map((study) => (
                      <React.Fragment key={study.StudyInstanceUID}>
                        <StyledTableRow
                          hover
                          role="checkbox"
                          tabIndex={-1}
                          onClick={() =>
                            toggleRowExpansion(study.StudyInstanceUID)
                          }
                          className={
                            expandedRows.includes(study.StudyInstanceUID)
                              ? "selected-row"
                              : ""
                          }
                        >
                          <TableCell>
                            <IconButton size="small">
                              {expandedRows.includes(study.StudyInstanceUID) ? (
                                <KeyboardArrowDown />
                              ) : (
                                <KeyboardArrowRight
                                  style={{ filter: "invert(1)" }}
                                />
                              )}
                            </IconButton>
                          </TableCell>
                          <TableCell>
                            {study.StudyDescription || "N/A"}
                          </TableCell>
                          <TableCell>{study.Modality}</TableCell>
                          <TableCell>{study.StudyDate}</TableCell>
                          <TableCell>{study.TotalInstancesInStudy}</TableCell>
                          <TableCell>
                            <IconButton
                              size="small"
                              onClick={(event) => handleMenuOpen(event, study)}
                            >
                              <MoreVert />
                            </IconButton>
                            <Menu
                              anchorEl={anchorEl}
                              open={
                                Boolean(anchorEl) &&
                                selectedStudyForMenu?.StudyInstanceUID ===
                                  study.StudyInstanceUID
                              }
                              key={selectedStudyForMenu?.StudyInstanceUID}
                              onClose={handleMenuClose}
                              sx={{
                                "& .MuiPaper-root": {
                                  backgroundColor: "#000000",
                                  color: "#ffffff",
                                },
                                "& .MuiMenuItem-root": {
                                  "&:hover": {
                                    backgroundColor: "#333333",
                                    color: "#ffffff",
                                  },
                                },
                              }}
                            >
                              <MenuItem
                                onClick={(event) => {
                                  event.stopPropagation();
                                  handleDownloadAll(study);
                                }}
                              >
                                Download Study
                              </MenuItem>
                              <MenuItem
                                onClick={(event) => {
                                  event.stopPropagation();
                                  handleSendStudyRecordsClick(
                                    study.StudyInstanceUID
                                  );
                                }}
                              >
                                Send Study Records
                              </MenuItem>
                              <MenuItem
                                onClick={(event) => {
                                  event.stopPropagation();
                                  handleShareStudyClick(study.StudyInstanceUID);
                                }}
                              >
                                Share Study
                              </MenuItem>
                              <MenuItem
                                onClick={(event) => {
                                  event.stopPropagation();
                                  handleLogsClick(study.StudyInstanceUID);
                                }}
                              >
                                Logs
                              </MenuItem>
                            </Menu>
                          </TableCell>
                        </StyledTableRow>
                        {expandedRows.includes(study.StudyInstanceUID) && (
                          <ExpandedTableRow ref={rowRef}>
                            <TableCell
                              colSpan={columns.length + 2}
                              style={{ padding: "16px", maxWidth: "50px" }}
                            >
                              {loadingThumbnails[study.StudyInstanceUID] && (
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    height: "100px",
                                  }}
                                >
                                  <CircularProgress
                                    style={{ color: "#A5E0FF" }}
                                  />
                                </div>
                              )}
                              <OuterContainer>
                                <ThumbnailList>
                                  {patientStudyMeta[study.StudyInstanceUID] &&
                                    patientStudyMeta[
                                      study.StudyInstanceUID
                                    ].Series.map((series) =>
                                      thumbnails[study.StudyInstanceUID] &&
                                      thumbnails[study.StudyInstanceUID][
                                        series.SeriesInstanceUID
                                      ] ? (
                                        <ThumbnailListItem
                                          key={series.SeriesInstanceUID}
                                        >
                                          <img
                                            src={
                                              thumbnails[
                                                study.StudyInstanceUID
                                              ][series.SeriesInstanceUID]
                                            }
                                            alt="Thumbnail"
                                            width={170}
                                            height={170}
                                            onClick={() =>
                                              handleThumbnailClick(
                                                study.StudyInstanceUID,
                                                study,
                                                series.SeriesInstanceUID,
                                                series.Instances[0]
                                                  .SOPInstanceUID
                                              )
                                            }
                                            onLoad={() => {
                                              setLoadingThumbnails((prev) => ({
                                                ...prev,
                                                [study.StudyInstanceUID]: false,
                                              }));
                                            }}
                                          />
                                        </ThumbnailListItem>
                                      ) : null
                                    )}
                                </ThumbnailList>
                              </OuterContainer>
                            </TableCell>
                          </ExpandedTableRow>
                        )}
                      </React.Fragment>
                    ))
                  )}
                </ScrollableTableBody>
              </Table>
              <TablePagination
                rowsPerPageOptions={[10, 25, 50]}
                component="div"
                count={totalRows || 0}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                sx={{
                  position: "sticky",
                  bottom: 0,
                  backgroundColor: "#3b4252",
                  zIndex: 2,
                  ".MuiTablePagination-select": {
                    backgroundColor: "#3b4252 !important",
                    color: "#FBFEFF !important",
                  },
                  ".MuiTablePagination-menuItem": {
                    backgroundColor: "#3b4252 !important",
                    color: "#FBFEFF !important",
                    "&:hover": {
                      backgroundColor: "#2B3342 !important",
                    },
                    "&.Mui-selected": {
                      backgroundColor: "#2B3342 !important",
                    },
                  },
                  ".MuiTablePagination-actions": {
                    color: "#FBFEFF !important",
                  },
                  boxShadow: "0px -2px 4px 0px #141B25",
                  overflow: "visible",
                }}
                slotProps={{
                  select: {
                    MenuProps: {
                      PaperProps: {
                        sx: {
                          backgroundColor: "#141b25",
                          color: "#FBFEFF",
                        },
                      },
                    },
                  },
                }}
              />
            </TableWrapper>
          )}
        </StyledTableContainer>
      </InfoTable>
      <DownloadProgressModal
        open={isDownloadModalOpen}
        studies={downloadQueue}
        onCancel={() => stopDownload()}
      />
      <LogsModal
        selectedPatient={patient}
        selectedStudyId={selectedStudy}
        open={isLogsModalOpen}
        onClose={() => setIsLogsModalOpen(false)}
      />
      <ShareStudyModal
        selectedPatient={patient}
        selectedStudyId={selectedStudy}
        open={isShareStudyModalOpen}
        onClose={() => setIsShareStudyModalOpen(false)}
      />
      <SendStudyRecordModal
        selectedPatient={patient}
        selectedStudyId={selectedStudy}
        open={isSendStudyRecordModalOpen}
        onClose={() => setisSendStudyRecordModalOpen(false)}
      />
    </div>
  );
});

export default PatientStudies;
