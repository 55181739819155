export const AddPaitent = "/patient_manager/add_patient";
export const AddPatient = "/patient_manager/add_patient";
export const GetAllPatient = "/patient_manager/all_patients_pagination/";
export const UpdatePaitent = "/patient_manager/update_patient";
export const GetMetric = "/patient_manager/metrics";
export const GetMamoMetrics = "/patient_manager/mamo_metrics";
export const DeletePatient = "/patient_manager/delete_patient";
export const GetPatientById = "/patient_manager/search_patientUUID";
export const SearchPatient = "/patient_manager/search";
export const GetPatientMetaData = "/dicom_manager2/patient_meta";
export const GetPatientVitalSigns = "/patient360-2/vital-signs";
export const GetPatientStudyMeta = "/dicom_manager2/study_meta";
export const GetPatientStudyList = "/dicom_manager2/patient_study_list";
export const TokenHistoryAPI = "/share_manager/share_study_history";
export const ShareStudy = "/share_manager/share_study";
export const GetLink = "/share_manager/share_study_url";
export const RenderDICOM = "/dicom_manager2/render";
export const RenderDICOM2 =
  "https://healthcare.googleapis.com/v1/projects/nexusmd-prod/locations/us/datasets/dicom.nexus-md.factstore/dicomStores/production_phi/dicomWeb/studies/";
export const PatientObservation = "/patient360-2/observations";
export const GetPatientDiagnostic = "/patient360-2/diagnosticReport";
export const GetPatientDocumentList = "/patient360-2/document-list";
export const GetPatientDocumentXML = "/patient360-2/document";
export const RadiologyReport = "/patient360-2/radiology-timeline";
export const PatientProcedures = "/patient360-2/procedures";
export const PatientEncounters = "/patient360-2/encounter";
export const BulkPullEHR = "/patient360-2/bulk-pull-ehr";
export const ListAttachments = "/file_manager/listfiles";
export const DownloadAttachment = "/file_manager/downloadfile";
export const GetStudyLogs = "/dicom_manager2/study_logs";
export const GetClientLocation = "/pacsLocations/client/get_location";
export const DcmsendStudy = "/nexusLink/dcmsend_study";
export const GetLocation = "/pacsLocations/user/get_location";
