import React from "react";
import { Typography } from "@mui/material";
import styled from "styled-components";
import PatientVitalStatsIntro from "../components/shared/PatientVitalStatsIntro";
import PatientA1C from "../components/shared/PatientA1C";

const Container = styled.div`
  background-color: #2b3342;
  border-radius: 20px;
  padding: 16px;
  box-shadow: 0px 4px 6px 0px #00000040;
  display: grid;
  place-items: center;
  font-size: 16px;
  color: white;
  overflow: auto;
  max-height: calc(100vh - 100px);
`;

const VisualComponents = () => {
  return (
    <Container>
      <Typography variant="h4">Visual Components</Typography>

      <div
        style={{
          display: "flex",
          width: "100%",
          gap: "70px",
          marginTop: "50px",
        }}
      >
        <div>
          <Typography variant="body1">Vital Stats Short-Form</Typography>
          <PatientVitalStatsIntro
            color="#f5a623"
            testTitle="Blood Pressure"
            testData="132/83"
            unit="mmHg"
            diagnosis="Prehypertension"
            timestamp="05/28/15, 9:13 AM"
          />

          <PatientVitalStatsIntro
            color="green"
            testTitle="Blood Pressure"
            testData="132/83"
            unit="mmHg"
            diagnosis="Prehypertension"
            timestamp="05/28/15, 9:13 AM"
          />
        </div>
        <div style={{ display: "flex", gap: "70px" }}>
          <div style={{ marginBottom: "50px" }}>
            <Typography variant="body1">Threshold Chart (ASC)</Typography>
            <PatientA1C
              ranges={[
                [0, 120],
                [120, 140],
                [140, 180],
                [180, null],
              ]}
              colors={["#43a047", "#fdd835", "#fb8c00", "#e53935"]}
              labels={[
                "Normal",
                "Prehypertension",
                "Hypertension 1",
                "Hypertension 2",
              ]}
              userValue={132}
              normalValue={110}
            />
          </div>
          <div>
            <Typography variant="body1">Threshold Chart (DESC)</Typography>
            <PatientA1C
              ranges={[
                [180, null],
                [140, 180],
                [120, 140],
                [0, 120],
              ]}
              colors={["#e53935", "#fb8c00", "#fdd835", "#43a047"]}
              labels={[
                "Hypertension 2",
                "Hypertension 1",
                "Prehypertension",
                "Normal",
              ]}
              userValue={70}
              normalValue={170}
            />
          </div>
        </div>
      </div>
    </Container>
  );
};

export default VisualComponents;
