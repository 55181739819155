import React from "react";
import { Box, Typography, Tooltip } from "@mui/material";
import styled from "styled-components";

const ChartContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 200px;
  height: 400px;
  border-radius: 12px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  position: relative;
  background-color: #ddd;
`;

const RangeSection = styled(Box)`
  flex-grow: 1;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: white;
  font-weight: bold;
  position: relative;
  flex-direction: column;
  padding: 8px;
`;

const IndicatorLine = styled(Box)`
  position: absolute;
  width: 100%;
  height: 2px;
  background-color: black;
  left: 0;
  cursor: pointer;
`;

const CircleIndicator = styled(Box)`
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-weight: bold;
  font-size: 14px;
  position: absolute;
`;

const isAscending = (ranges) => {
  return (
    ranges[0][0] <
    (ranges[ranges.length - 1][1] || ranges[ranges.length - 1][0])
  );
};

const calculatePosition = (
  value,
  originalRanges,
  containerHeight,
  labels,
  ascendingOrder
) => {
  const ascendingRanges = [...originalRanges].sort((a, b) => a[0] - b[0]);

  const originalIndexMap = originalRanges.map((range) =>
    ascendingRanges.findIndex(
      (sortedRange) =>
        sortedRange[0] === range[0] && sortedRange[1] === range[1]
    )
  );

  let rangeIndex = -1;
  let minRange = 0;
  let maxRange = 0;
  let rangeLabel = "";
  let label = "";

  for (let i = 0; i < ascendingRanges.length; i++) {
    if (
      ascendingRanges[i][1] === null ||
      (value >= ascendingRanges[i][0] && value <= ascendingRanges[i][1])
    ) {
      rangeIndex = i;
      minRange = ascendingRanges[i][0];
      maxRange = ascendingRanges[i][1] ?? ascendingRanges[i][0] + 20;
      rangeLabel = ascendingRanges[i][1]
        ? `${ascendingRanges[i][0]} to ${ascendingRanges[i][1]}`
        : `${ascendingRanges[i][0]} +`;

      const originalLabelIndex = originalIndexMap.findIndex(
        (index) => index === i
      );
      label = labels[originalLabelIndex];

      break;
    }
  }

  if (rangeIndex === -1)
    return { position: containerHeight, rangeLabel, label };

  if (value > maxRange) return { position: 0, rangeLabel, label };
  if (value < minRange) return { position: containerHeight, rangeLabel, label };

  const rangeHeight = containerHeight / ascendingRanges.length;
  const percentage = (value - minRange) / (maxRange - minRange);
  const position = rangeIndex * rangeHeight + percentage * rangeHeight;

  return { position: containerHeight - position, rangeLabel, label };
};

const PatientA1C = ({ ranges, colors, labels, userValue, normalValue }) => {
  const containerHeight = 400;
  const ascendingOrder = isAscending(ranges);
  const reversedOrder = !ascendingOrder;

  const {
    position: userPosition,
    rangeLabel: userRange,
    label: userLabel,
  } = calculatePosition(userValue, ranges, containerHeight, labels);

  const {
    position: normalPosition,
    rangeLabel: normalRange,
    label: normalLabel,
  } = calculatePosition(normalValue, ranges, containerHeight, labels);

  return (
    <ChartContainer>
      {ranges.map((range, index) => (
        <RangeSection key={index} sx={{ backgroundColor: colors[index] }}>
          <Typography variant="body2">{labels[index]}</Typography>
          <Typography variant="caption">
            {range[1] ? `${range[0]} to ${range[1]}` : `${range[0]} +`}
          </Typography>
        </RangeSection>
      ))}

      <Tooltip
        title={
          <Box>
            <Typography fontWeight="bold">User Value</Typography>
            <Typography>Measurement: {userValue}</Typography>
            <Typography>
              Range: {userRange} ({userLabel})
            </Typography>
          </Box>
        }
        arrow
        placement="right"
      >
        <IndicatorLine
          sx={{
            [reversedOrder ? "top" : "bottom"]: `${userPosition}px`,
          }}
        />
      </Tooltip>

      <CircleIndicator
        sx={{
          [reversedOrder ? "top" : "bottom"]: `${userPosition - 15}px`,
          left: -40,
        }}
      >
        {userValue}
      </CircleIndicator>

      <Tooltip
        title={
          <Box>
            <Typography fontWeight="bold">Normal Value</Typography>
            <Typography>Measurement: {normalValue}</Typography>
            <Typography>
              Range: {normalRange} ({normalLabel})
            </Typography>
          </Box>
        }
        arrow
        placement="right"
      >
        <IndicatorLine
          sx={{
            [reversedOrder ? "top" : "bottom"]: `${normalPosition}px`,
          }}
        />
      </Tooltip>

      <CircleIndicator
        sx={{
          [reversedOrder ? "top" : "bottom"]: `${normalPosition - 15}px`,
          left: -40,
          border: "2px dotted white",
        }}
      >
        {normalValue}
      </CircleIndicator>
    </ChartContainer>
  );
};

export default PatientA1C;
