import React from "react";
import { Modal, CircularProgress, Button } from "@mui/material";
import styled from "styled-components";
import { CheckCircle, Error } from "@mui/icons-material";

const DownloadModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #141b25;
  padding: 20px;
  border-radius: 10px;
  color: white;
  width: 350px;
  max-height: 80vh;
  overflow-y: auto;
  margin-left: auto;
  margin-right: auto;
  margin-top: 15vh;
`;

const StudyList = styled.div`
  width: 100%;
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const StudyItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px;
  background-color: ${({ status }) =>
    status === "downloading"
      ? "#3b4252"
      : status === "success"
      ? "#2e7d32"
      : status === "failed"
      ? "#b71c1c"
      : "#222"};
  border-radius: 5px;
  color: white;
  font-size: 14px;
`;

const DownloadProgressModal = ({ open, studies, onCancel }) => {
  return (
    <Modal open={open}>
      <DownloadModalContainer>
        <h3>Downloading Studies</h3>
        <StudyList>
          {studies.map((study, index) => (
            <StudyItem key={index} status={study.status}>
              {study.description}
              {study.status === "downloading" && <CircularProgress size={16} />}
              {study.status === "success" && (
                <CheckCircle style={{ color: "lightgreen" }} />
              )}
              {study.status === "failed" && <Error style={{ color: "red" }} />}
            </StudyItem>
          ))}
        </StudyList>

        <Button
          variant="contained"
          color="primary"
          onClick={onCancel}
          style={{ marginTop: "20px" }}
        >
          Close
        </Button>
      </DownloadModalContainer>
    </Modal>
  );
};

export default DownloadProgressModal;
