import React from "react";
import { Box, Typography } from "@mui/material";
import styled from "styled-components";

const CardContainer = styled(Box)`
  display: flex;
  align-items: center;
  background-color: #141b25;
  padding: 8px 12px;
  border-radius: 8px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  max-width: 250px;
  margin-bottom: 16px;
`;

const Circle = styled(Box)`
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: ${(props) => props.color || "#ccc"};
  margin-right: 16px;
`;

const TextContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

const TestData = styled(Typography)`
  font-size: 20px;
  font-weight: bold;
  color: ${(props) => props.color || "#ccc"};
`;

const DiagnoseText = styled(Typography)`
  font-size: 12px;
  color: ${(props) => props.color || "#ccc"};
  font-weight: bold;
`;

const TimeText = styled(Typography)`
  font-size: 12px;
  color: #666;
`;

const PatientVitalStatsIntro = ({
  color,
  testTitle,
  testData,
  unit,
  diagnosis,
  timestamp,
}) => {
  return (
    <CardContainer>
      <Circle color={color} />
      <TextContainer>
        <Typography variant="caption">{testTitle}</Typography>
        <TestData color={color}>
          {testData}
          <Typography variant="caption" sx={{ color: color }}>
            {unit}
          </Typography>
        </TestData>
        <DiagnoseText color={color}>{diagnosis}</DiagnoseText>
        <TimeText>{timestamp}</TimeText>
      </TextContainer>
    </CardContainer>
  );
};

export default PatientVitalStatsIntro;
