import React, { useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  CircularProgress,
} from "@mui/material";
import styled from "styled-components";
import Notification from "./Notification";
import TextPreview from "./TextPreview";
import { CopyAll, Launch } from "@mui/icons-material";

const BackdropOverlay = styled.div`
  display: ${({ open }) => (open ? "flex" : "none")};
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  z-index: 1300;
`;

const StyledTableContainer = styled.div`
  background-color: #2b3342;
  border-radius: 8px;
  overflow-x: auto; /* Enable horizontal scrolling */

  &::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }

  &::-webkit-scrollbar-thumb {
    background: #3e526a;
    border-radius: 100px;
  }

  &::-webkit-scrollbar-track {
    background: #141b25;
    border-radius: 100px;
  }
`;

const StyledTable = styled(Table)`
  background-color: #2b3342;
  color: #fbfeff;

  .MuiTableHead-root {
    background-color: #3e526a;
  }

  .MuiTableCell-head {
    color: #a5e0ff;
    font-weight: bold;
    text-transform: capitalize;
    font-size: 14px;
    border-bottom: 1px solid #3e526a;
  }

  .MuiTableBody-root .MuiTableCell-root {
    border-bottom: 1px solid #3e526a;
    color: #fbfeff;
    font-size: 14px;
    word-wrap: break-word;
    text-overflow: ellipsis;
  }

  .MuiTableRow-root:hover {
    background-color: #3b4252;
  }
`;

const StyledTableCell = styled(TableCell)`
  &:not(:last-child) {
    border-right: none;
  }

  &.token-cell {
    white-space: nowrap;
    overflow: visible;
    text-overflow: ellipsis;
  }

  &.token-cell span {
    cursor: pointer;
    color: #5ab1ff;
    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }
  }
`;

const TokenHistory = ({ tokenHistory, loading }) => {
  const [notification, setNotification] = useState({
    open: false,
    message: "",
    severity: "success",
  });

  const handleCloseNotification = () => {
    setNotification({ open: false, message: "", severity: "success" });
  };

  const handleRedirect = (studyId, token) => {
    const baseUrl = "https://basicviewer.nexus-md.com";
    const url = `${baseUrl}/?token=${token}&studyId=${studyId}`;
    window.open(url, "_blank");
  };

  const handleCopyPath = (token, studyId) => {
    const baseUrl = "https://basicviewer.nexus-md.com";
    const url = `${baseUrl}/?token=${token}&studyId=${studyId}`;
    navigator.clipboard.writeText(url);
  };

  return (
    <div>
      <BackdropOverlay open={loading}>
        <CircularProgress color="inherit" />
        <span style={{ marginLeft: "10px" }}>Token History Loading...</span>
      </BackdropOverlay>

      {!loading && (
        <StyledTableContainer>
          <StyledTable>
            <TableHead>
              <TableRow>
                <StyledTableCell>Expire</StyledTableCell>
                <StyledTableCell>Patient ID</StyledTableCell>
                <StyledTableCell>Shared By</StyledTableCell>
                <StyledTableCell>Study UID</StyledTableCell>
                <StyledTableCell>Token</StyledTableCell>
                <StyledTableCell>Action</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {tokenHistory.length > 0 ? (
                tokenHistory.map((tokenData, index) => (
                  <TableRow key={index}>
                    <StyledTableCell>{tokenData.expire}</StyledTableCell>
                    <StyledTableCell>
                      <TextPreview text={tokenData.PatientID} clipboardCopy />
                    </StyledTableCell>
                    <StyledTableCell>{tokenData.sharedBy}</StyledTableCell>
                    <StyledTableCell>
                      <TextPreview text={tokenData.studyUID} clipboardCopy />
                    </StyledTableCell>
                    <StyledTableCell className="token-cell">
                      <TextPreview text={tokenData.token} clipboardCopy />
                    </StyledTableCell>
                    <StyledTableCell className="token-cell">
                      <Launch
                        onClick={() =>
                          handleRedirect(tokenData.studyUID, tokenData.token)
                        }
                        style={{ cursor: "pointer" }}
                      />
                      <CopyAll
                        onClick={() => {
                          handleCopyPath(tokenData.token, tokenData.studyUID);
                          setNotification({
                            open: true,
                            message: "Copied successfully!",
                            severity: "success",
                          });
                        }}
                        style={{ cursor: "pointer", marginLeft: "10px" }}
                      ></CopyAll>
                    </StyledTableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={6} style={{ textAlign: "center" }}>
                    No token history available
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </StyledTable>
        </StyledTableContainer>
      )}

      <Notification
        open={notification.open}
        message={notification.message}
        severity={notification.severity}
        handleClose={handleCloseNotification}
      />
    </div>
  );
};

export default TokenHistory;
