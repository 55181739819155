import React, { useState, useEffect } from "react";
import {
  Autocomplete,
  Dialog,
  DialogActions,
  DialogContent,
  Button,
  CircularProgress,
  Backdrop,
} from "@mui/material";
import styled from "styled-components";
import StyledTextField from "./StyledTextField";
import {
  GetLocation,
  GetClientLocation,
  DcmsendStudy,
} from "../../api/Patient";
import Notification from "./Notification";
import api from "../../api/config";
import ModalHeader from "./ModalHeader";

const Content = styled(DialogContent)`
  background-color: #2b3342;
  color: white;
  padding: 16px;
`;

const Footer = styled(DialogActions)`
  background-color: #28929c;
  padding: 10px;
  font-size: 14px;
`;

const LocationOption = styled("div")`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const LocationName = styled("span")`
  font-size: 14px;
  color: white;
`;

const LocationDetail = styled("span")`
  font-size: 11px;
  color: red;
`;

const SendStudyRecordModal = ({
  open,
  onClose,
  selectedPatient,
  selectedStudyId,
}) => {
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [locations, setLocations] = useState([]);
  const [baseOptions, setBaseOptions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isSending, setIsSending] = useState(false);
  const [notification, setNotification] = useState({
    open: false,
    message: "",
    severity: "",
  });

  useEffect(() => {
    const fetchLocations = async () => {
      setLoading(true);
      try {
        const response = await api.get(GetLocation);
        if (response.data) {
          const formattedLocations = response.data.map((x) => ({
            value: x.uuid,
            label: x.LocationName,
            AET: x.AET,
            IPAddress: x.IPAddress,
            Port: x.Port,
          }));
          setLocations(formattedLocations);
          setBaseOptions(formattedLocations);
          await fetchClientLocations();
        }
      } catch (error) {
        console.error("Error fetching locations:", error);
      } finally {
        setLoading(false);
      }
    };

    const fetchClientLocations = async () => {
      try {
        let clientUID = localStorage.getItem(`clientuuid`);
        const response = await api.get(
          `${GetClientLocation}?ClientUID=${clientUID}`
        );
        if (response.data) {
          const clientLocations = response.data.map((x) => ({
            value: x.uuid,
            label: x.enpoint_name,
            AET: x.sendAET,
            IPAddress: x.sendIP,
            Port: x.sendPort,
          }));
          setLocations((prevLocations) => [
            ...prevLocations,
            ...clientLocations,
          ]);
          setBaseOptions((prevBaseOptions) => [
            ...prevBaseOptions,
            ...clientLocations,
          ]);
        }
      } catch (error) {
        console.error("Error fetching client locations:", error);
      }
    };

    if (selectedPatient) {
      fetchLocations();
    }
  }, [selectedPatient]);

  const handleFilter = (event, value) => {
    if (value === "") {
      setLocations(baseOptions);
    } else {
      setLocations(
        baseOptions.filter((location) =>
          location.label.toLowerCase().includes(value.toLowerCase())
        )
      );
    }
  };

  const handleSendRequest = async () => {
    setIsSending(true);
    try {
      const response = await api.post(
        `${DcmsendStudy}/${selectedPatient.uuid}/${selectedStudyId}/${selectedLocation.AET}/${selectedLocation.IPAddress}/${selectedLocation.Port}`
      );
      console.log("Send Dcm successfully:", response.data);
      setNotification({
        open: true,
        message: "Study records sent successfully",
        severity: "success",
      });
    } catch (error) {
      console.error("Error sending records:", error);
      setNotification({
        open: true,
        message: "Error sending study records",
        severity: "error",
      });
    } finally {
      setIsSending(false);
      setSelectedLocation(null);
      handleClose();
    }
  };

  const handleClose = () => {
    setSelectedLocation(null);
    onClose();
  };

  const handleNotificationClose = () => {
    setNotification({ open: false, message: "", severity: "" });
  };

  return (
    <>
      <Notification
        open={notification.open}
        message={notification.message}
        severity={notification.severity}
        handleClose={handleNotificationClose}
      />
      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
          sx: {
            minWidth: "550px",
            maxWidth: "80vw",
          },
        }}
      >
        <Backdrop open={isSending} style={{ zIndex: 9999, color: "#fff" }}>
          <CircularProgress color="inherit" />
        </Backdrop>

        <ModalHeader title="Send Study Records" onClose={handleClose} />

        <Content>
          <div
            style={{
              marginBottom: "16px",
              marginTop: "16px",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <div>
              <strong>Full Name: </strong> {selectedPatient?.first_name}{" "}
              {selectedPatient?.last_name}
            </div>
            <div>
              <strong>DOB: </strong> {selectedPatient?.date_of_birth}
            </div>
            <div>
              <strong>Gender: </strong> {selectedPatient?.gender}
            </div>
          </div>

          <Autocomplete
            style={{ marginTop: "16px" }}
            options={locations}
            getOptionLabel={(option) => option.label}
            filterOptions={(options, { inputValue }) => {
              return options.filter((option) =>
                option.label.toLowerCase().includes(inputValue.toLowerCase())
              );
            }}
            value={selectedLocation}
            onChange={(event, newValue) => setSelectedLocation(newValue)}
            onInputChange={handleFilter}
            loading={loading}
            renderOption={(props, option) => (
              <li {...props}>
                <LocationOption>
                  <LocationName>{option.label}</LocationName>
                  <LocationDetail>AET: {option.AET}</LocationDetail>
                  <LocationDetail>
                    IP: {option.IPAddress}:{option.Port}
                  </LocationDetail>
                </LocationOption>
              </li>
            )}
            ListboxProps={{
              style: { backgroundColor: "#141b25", color: "#FBFEFF" },
            }}
            renderInput={(params) => (
              <StyledTextField
                {...params}
                label="Locations"
                variant="outlined"
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <>
                      {loading ? (
                        <CircularProgress size={20} color="inherit" />
                      ) : null}
                      {params.InputProps.endAdornment}
                    </>
                  ),
                }}
                sx={{
                  "& .MuiOutlinedInput-root": { color: "white" },
                  "& .MuiInputLabel-root": { color: "white" },
                }}
              />
            )}
            PaperProps={{
              sx: {
                backgroundColor: "#141b25",
                "& .MuiAutocomplete-option": {
                  color: "black",
                  '&[data-focus="true"]': {
                    backgroundColor: "#28929C",
                    color: "white",
                  },
                },
              },
            }}
            sx={{
              color: "white",
              ".MuiOutlinedInput-notchedOutline": {
                borderColor: "#ffffff",
                borderRadius: "12px",
              },
              "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                borderColor: "#ffffff",
                borderRadius: "12px",
              },
              "&:hover .MuiOutlinedInput-notchedOutline": {
                borderColor: "#ffffff",
                borderRadius: "12px",
              },
              ".MuiSvgIcon-root": { fill: "white !important" },
            }}
          />
        </Content>

        <Footer>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button
            onClick={handleSendRequest}
            color="primary"
            disabled={!selectedLocation || isSending}
          >
            {isSending ? (
              <CircularProgress size={20} color="inherit" />
            ) : (
              "Send"
            )}
          </Button>
        </Footer>
      </Dialog>
    </>
  );
};

export default SendStudyRecordModal;
