import React, { useRef, useState } from "react";
import styled from "styled-components";
import PatientInformation from "./patient/PatientInformation";
import { Tabs, Tab } from "@mui/material";
import PatientStudies from "./patient/PatientStudies";
import PatientDetailedEHR from "./patient/PatientDetailedEHR";
import { Download } from "@mui/icons-material";

const Container = styled.div`
  width: ${({ containerWidth }) => `${containerWidth}px` || "100%"};
  background-color: #141b25;
  border-radius: 8px;
  color: white;
  display: flex;
  flex-direction: column;
  height: 100%;
  position: relative;
`;

const Header = styled.div`
  background-color: #0088cf;
  display: flex;
  justify-content: space-between;
  padding: 0px 10px 0px 10px;
  align-items: center;
  flex-shrink: 0;
  position: sticky;
  top: 0;
  z-index: 5;
`;

const HeaderTitle = styled.div`
  padding-left: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 0;
`;

const HeaderButtons = styled.div`
  display: flex;
  gap: 8px;
  padding-right: 15px;
`;

const Button = styled.button`
  background-color: #3b4252;
  color: #fbfeff;
  border: none;
  border-radius: 100px;
  padding: 8px 16px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 8px;

  &:hover {
    background-color: #4c566a;
  }
`;

const MessageContainer = styled.div`
  padding: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
`;

const StyledTabs = styled(Tabs)`
  border-bottom: 1px solid #7efbfd;
  & .MuiTabs-indicator {
    background-color: #7efbfd;
    height: 4px;
  }

  & .MuiTab-root {
    color: white;
    font-size: 16px;
    text-transform: capitalize;
  }

  & .Mui-selected {
    font-weight: bold;
    color: #7efbfd !important;
  }
`;

const StyledTab = styled(Tab)`
  color: #fbfeff;

  &.Mui-selected {
    font-weight: bold;
    color: #7efbfd;
  }
`;

const MainContent = styled.div`
  flex-grow: 1;
  overflow-y: auto;
  padding: 16px;
  border-radius: 8px 8px 0px 0px;
  display: flex;
  flex-direction: column;
`;

const PatientDetails = ({ patient, containerWidth }) => {
  const [selectedTab, setSelectedTab] = useState(0);
  const patientStudiesRef = useRef(null);

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const renderTabContent = () => {
    switch (selectedTab) {
      case 2:
        return <PatientInformation patient={patient} />;
      case 1:
        return <PatientDetailedEHR patient={patient} />;
      case 0:
        return <PatientStudies ref={patientStudiesRef} patient={patient} />;
      default:
        return null;
    }
  };

  if (!patient) {
    return (
      <div>
        <MessageContainer>Select a patient to see details</MessageContainer>
      </div>
    );
  }

  const handleDownloadAll = () => {
    if (selectedTab === 0 && patientStudiesRef.current) {
      patientStudiesRef.current.downloadAll();
    }
  };

  return (
    <Container containerWidth={containerWidth}>
      <Header>
        <HeaderTitle>
          <h2 style={{ margin: "5px", textTransform: "capitalize" }}>
            {`${patient.first_name} ${patient.last_name}`},{" "}
            {`${patient.date_of_birth}`}
          </h2>
          <p style={{ marginLeft: "5px", marginTop: "2px", marginBottom: "0" }}>
            Patient ID: {patient.client_patient_uuid}
          </p>
          <p style={{ marginLeft: "5px", marginTop: "2px" }}>
            Gender:{" "}
            {patient.gender.charAt(0).toUpperCase() + patient.gender.slice(1)}
          </p>
        </HeaderTitle>
        <HeaderButtons>
          {selectedTab === 0 && (
            <Button onClick={() => handleDownloadAll()}>
              <Download />
              Download All Studies
            </Button>
          )}
          {/* <Button>
            <ShareIcon />
            Share Patient
          </Button>
          <Button>
            <Download />
            Import Imaging
          </Button>
          <Button>
            <Publish />
            Export Imaging
          </Button> */}
        </HeaderButtons>
      </Header>
      <MainContent>
        <StyledTabs value={selectedTab} onChange={handleTabChange}>
          <StyledTab label="Imaging" />
          <StyledTab label="External Documents" />
          <StyledTab label="Patient Information" />
        </StyledTabs>
        {renderTabContent()}
      </MainContent>
    </Container>
  );
};

export default PatientDetails;
