import React, { useRef, useState, useCallback, useEffect } from "react";
import styled from "styled-components";
import AllPatientsTable from "../components/AllPatientsTable";
import PatientDetails from "../components/PatientDetails";

const Container = styled.div`
  display: flex;
  max-height: calc(100vh - 100px);
  box-sizing: border-box;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const LeftContainer = styled.div`
  background-color: #2b3342;
  border-radius: 20px;
  padding: 16px;
  box-shadow: 0px 4px 6px 0px #00000040;
  display: grid;
  place-items: center;
  font-size: 16px;
  color: white;
  overflow: auto;
  min-width: 100px;
`;

const RightContainer = styled.div`
  background-color: #2b3342;
  border: 4px solid #0088cf;
  border-radius: 20px;
  box-shadow: 0px 4px 6px 0px #00000040;
  display: grid;
  place-items: center;
  font-size: 16px;
  color: white;
  flex-grow: 1;
  overflow-y: auto;
  overflow-x: hidden;

  &::-webkit-scrollbar {
    width: 12px;
  }

  &::-webkit-scrollbar-track {
    background: #283a52;
    border-radius: 100px;
  }

  &::-webkit-scrollbar-thumb {
    background: #28929c;
    border: 1px solid #3e526a;
    border-radius: 100px;
    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.3);
  }
`;

const Resizer = styled.div`
  width: 12px;
  cursor: col-resize;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  transition: background-color 0.15s 0.15s ease-in-out;

  &:hover,
  &.dragging {
    background-color: #63b3ed;
  }

  @media (max-width: 768px) {
    display: none;
  }
`;

const Home = () => {
  const [leftContainerWidth, setLeftContainerWidth] = useState(550);
  const [isDragging, setIsDragging] = useState(false);
  const [selectedPatient, setSelectedPatient] = useState(null);
  const containerRef = useRef(null);
  const [toolbarWidth, setToolbarWidth] = useState(0);

  useEffect(() => {
    setToolbarWidth(localStorage.getItem("toolbarWidth") || 0);
  }, []);

  const handleMouseDown = () => {
    setIsDragging(true);
  };

  const handleMouseMove = useCallback(
    (e) => {
      if (!isDragging) return;
      const newWidth =
        e.clientX - containerRef.current.getBoundingClientRect().left;
      if (newWidth >= 100) {
        setLeftContainerWidth(newWidth);
      }
    },
    [isDragging]
  );

  const handleMouseUp = () => {
    setIsDragging(false);
  };

  useEffect(() => {
    if (isDragging) {
      window.addEventListener("mousemove", handleMouseMove);
      window.addEventListener("mouseup", handleMouseUp);
    } else {
      window.removeEventListener("mousemove", handleMouseMove);
      window.removeEventListener("mouseup", handleMouseUp);
    }
    return () => {
      window.removeEventListener("mousemove", handleMouseMove);
      window.removeEventListener("mouseup", handleMouseUp);
    };
  }, [isDragging, handleMouseMove]);

  const handleToggleView = (showTable) => {
    setLeftContainerWidth(showTable ? 400 : 100);
  };

  return (
    <Container ref={containerRef}>
      <LeftContainer style={{ width: `${leftContainerWidth}px` }}>
        <AllPatientsTable
          onSelectPatient={setSelectedPatient}
          onToggleView={handleToggleView}
        />
      </LeftContainer>
      <Resizer
        onMouseDown={handleMouseDown}
        className={isDragging ? "dragging" : ""}
      ></Resizer>
      <RightContainer>
        <PatientDetails
          patient={selectedPatient}
          containerWidth={
            toolbarWidth ? toolbarWidth - leftContainerWidth - 90 : 0
          }
        />
      </RightContainer>
    </Container>
  );
};

export default Home;
