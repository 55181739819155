import React, { useState } from "react";
import { Tooltip, IconButton } from "@mui/material";
import styled from "styled-components";
import Notification from "./Notification";
import { VisibilityOff, Visibility } from "@mui/icons-material";

const TextContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  color: #5ab1ff;

  span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    cursor: ${({ clipboardCopy }) => (clipboardCopy ? "pointer" : "default")};
    text-decoration: ${({ clipboardCopy }) =>
      clipboardCopy ? "underline" : "none"};

    &:hover {
      text-decoration: ${({ clipboardCopy }) =>
        clipboardCopy ? "none" : "none"};
    }
  }
`;

const TextPreview = ({ text, clipboardCopy = false }) => {
  const [showFullText, setShowFullText] = useState(false);
  const [notification, setNotification] = useState({
    open: false,
    message: "",
    severity: "success",
  });

  const handleCopyToClipboard = () => {
    if (clipboardCopy) {
      navigator.clipboard.writeText(text);
      setNotification({
        open: true,
        message: "Copied successfully!",
        severity: "success",
      });
    }
  };

  const handleCloseNotification = () => {
    setNotification({ open: false, message: "", severity: "success" });
  };

  return (
    <>
      <TextContainer clipboardCopy={clipboardCopy}>
        <Tooltip title={showFullText ? "Hide full text" : "View full text"}>
          <IconButton
            size="small"
            onClick={() => setShowFullText(!showFullText)}
          >
            {showFullText ? (
              <VisibilityOff fontSize="small" />
            ) : (
              <Visibility fontSize="small" />
            )}
          </IconButton>
        </Tooltip>
        <span onClick={clipboardCopy ? handleCopyToClipboard : undefined}>
          {showFullText ? text : `${text.slice(0, 5)}...${text.slice(-5)}`}
        </span>
      </TextContainer>

      <Notification
        open={notification.open}
        message={notification.message}
        severity={notification.severity}
        handleClose={handleCloseNotification}
      />
    </>
  );
};

export default TextPreview;
