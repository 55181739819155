import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  Tabs,
  CircularProgress,
  Tab,
  Backdrop,
  Typography,
} from "@mui/material";
import styled from "styled-components";
import { ShareStudy, GetLink } from "../../api/Patient";
import StyledTextField from "./StyledTextField";
import api from "../../api/config";
import Notification from "./Notification";
import { TokenHistoryAPI } from "../../api/Patient";
import TokenHistory from "./TokenHistory";
import { CopyAll } from "@mui/icons-material";
import ModalHeader from "./ModalHeader";

const Content = styled(DialogContent)`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 16px;
  background-color: #2b3342;
  color: white;
`;

const Footer = styled(DialogActions)`
  background-color: #28929c;
  padding: 10px;
`;

const LinkBox = styled.div`
  background-color: #1b2735;
  color: #5ab1ff;
  padding: 10px;
  border-radius: 8px;
  margin-top: 10px;
  word-break: break-all;
  text-align: center;
  a {
    color: #5ab1ff;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
`;

const ShareStudyModal = ({
  selectedStudyId,
  selectedPatient,
  open,
  onClose,
}) => {
  const [activeTab, setActiveTab] = useState(0);
  const [email, setEmail] = useState("");
  const [numberOfDays, setNumberOfDays] = useState("180");
  const [notification, setNotification] = useState({
    open: false,
    message: "",
    severity: "",
  });
  const [url, setUrl] = useState("");
  const [loading, setLoading] = useState(false);
  const [tokenHistory, setTokenHistory] = useState([]);
  const [tokenHistoryLoading, setTokenHistoryLoading] = useState(false);

  useEffect(() => {
    if (open) {
      getTokenHistory();
    }
  }, [open]);

  const handleTabChange = (event, newValue) => {
    setEmail("");
    setNumberOfDays("180");
    setUrl("");
    setNotification({ open: false, message: "", severity: "" });
    setLoading(false);
    setActiveTab(newValue);
    if (newValue === 2) {
      getTokenHistory();
    }
  };

  const handleCloseNotification = () => {
    setNotification({ open: false, message: "", severity: "" });
  };

  const handleShare = async () => {
    setLoading(true);
    try {
      if (activeTab === 0) {
        console.log("Sharing via Email:", email, numberOfDays);
        const response = await api.post(
          `${ShareStudy}/${selectedPatient.uuid}/${selectedStudyId}?targetEmail=${email}&NumberOfDays=${numberOfDays}`
        );
        setUrl(response.data.URL);
        setNotification({
          open: true,
          message: response.data.status,
          severity: "success",
        });
        console.log("Response:", response);
      } else if (activeTab === 1) {
        console.log("Generating Link for:", numberOfDays);
        console.log("selectedPatient:", selectedPatient);
        const response = await api.get(
          `${GetLink}/${selectedPatient.uuid}/${selectedStudyId}?&NumberOfDays=${numberOfDays}`
        );
        setUrl(response.data.URL);
        setNotification({
          open: true,
          message: "Link generated successfully",
          severity: "success",
        });
        console.log("Response:", response);
      } else {
      }
    } catch (error) {
      console.error(error);
      setNotification({
        open: true,
        message: "Error sharing study",
        severity: "error",
      });
    } finally {
      setLoading(false);
    }
  };

  const handleClose = () => {
    setEmail("");
    setNumberOfDays("180");
    setUrl("");
    setNotification({ open: false, message: "", severity: "" });
    setLoading(false);
    onClose();
  };

  const getTokenHistory = async () => {
    try {
      setTokenHistoryLoading(true);
      console.log("Getting token history for:", selectedPatient);
      const response = await api.get(
        `${TokenHistoryAPI}/${selectedPatient.uuid}/${selectedStudyId}`
      );

      setTokenHistory(response.data || []);
      console.log("Token History:", response);
    } catch (error) {
      console.error("Error getting token history:", error);
    } finally {
      setTokenHistoryLoading(false);
    }
  };

  const handleCopyToClipboard = (text) => {
    navigator.clipboard.writeText(text);
    setNotification({
      open: true,
      message: "Copied successfully!",
      severity: "success",
    });
  };

  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth="lg">
      <Backdrop open={loading} style={{ zIndex: 9999, color: "#fff" }}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <ModalHeader title="Share Study" onClose={handleClose} />
      <Content style={{ padding: "20px" }}>
        <div
          style={{
            marginBottom: "16px",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <div>
            <strong>Patient: </strong> {selectedPatient?.first_name}{" "}
            {selectedPatient?.last_name}
          </div>
          <div>
            <strong>DOB: </strong> {selectedPatient?.date_of_birth}
          </div>
          <div>
            <strong>Gender: </strong> {selectedPatient?.gender}
          </div>
        </div>
        <Tabs
          value={activeTab}
          onChange={handleTabChange}
          indicatorColor="primary"
          textColor="inherit"
          centered
          sx={{
            backgroundColor: "#2B3342",
            color: "#FFFFFF",
            borderBottom: "1px solid #28929C",
            paddingBottom: "10px",
          }}
        >
          <Tab
            sx={{
              backgroundColor: "#95949452",
              borderRadius: "10px",
              marginRight: "10px",
            }}
            label="Share Email"
          />
          <Tab
            sx={{
              backgroundColor: "#95949452",
              borderRadius: "10px",
              marginRight: "10px",
            }}
            label="Get Link"
          />
          <Tab
            sx={{ backgroundColor: "#95949452", borderRadius: "10px" }}
            label="Token History"
          />
        </Tabs>

        {activeTab === 0 ? (
          <>
            <StyledTextField
              label="Email"
              variant="outlined"
              fullWidth
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <StyledTextField
              label="Number of Days"
              variant="outlined"
              fullWidth
              type="number"
              value={numberOfDays}
              onChange={(e) => setNumberOfDays(e.target.value)}
            />
          </>
        ) : activeTab === 1 ? (
          <StyledTextField
            label="Number of Days"
            variant="outlined"
            fullWidth
            type="number"
            value={numberOfDays}
            onChange={(e) => setNumberOfDays(e.target.value)}
          />
        ) : (
          <TokenHistory
            tokenHistory={tokenHistory}
            loading={tokenHistoryLoading}
          />
        )}

        {url && (
          <LinkBox>
            <Typography>
              <strong>Shareable Link:</strong>
            </Typography>
            <a href={url} target="_blank" rel="noopener noreferrer">
              {url}
            </a>
            <CopyAll
              onClick={() => handleCopyToClipboard(url)}
              style={{ marginLeft: "10px", cursor: "pointer" }}
            ></CopyAll>
          </LinkBox>
        )}
      </Content>
      <Footer>
        <Button onClick={handleClose} color="primary">
          Cancel
        </Button>
        {activeTab === 2 ? null : (
          <Button variant="contained" color="secondary" onClick={handleShare}>
            Share
          </Button>
        )}
      </Footer>
      <Notification
        open={notification.open}
        message={notification.message}
        severity={notification.severity}
        handleClose={handleCloseNotification}
      />
    </Dialog>
  );
};

export default ShareStudyModal;
