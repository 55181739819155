import React, { useCallback, useEffect, useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogActions,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableContainer,
  Paper,
  CircularProgress,
} from "@mui/material";
import styled from "styled-components";
import api from "../../api/config";
import { GetStudyLogs } from "../../api/Patient";
import ModalHeader from "./ModalHeader";

const Content = styled(DialogContent)`
  background-color: #2b3342;
  color: white;
  padding: 16px;
`;

const StyledTableContainer = styled(TableContainer)`
  margin-top: 16px;
  max-height: 500px;
  background-color: #2b3342;
`;

const StyledTableCell = styled(TableCell)`
  color: white !important;
  background-color: #2b3342;
`;

const HeaderStyledTableCell = styled(TableCell)`
  color: white !important;
`;

const DialogActionsStyled = styled(DialogActions)`
  justify-content: space-between;
  padding: 16px;
  background: #28929c;
`;

const Button = styled.button`
  background-color: #3b4252;
  color: #fbfeff;
  border: none;
  border-radius: 100px;
  padding: 8px 16px;
  cursor: pointer;
  display: flex;
  max-height: 40px;
  margin-bottom: 15px;
  align-items: center;
  gap: 8px;

  &:hover {
    background-color: #4c566a;
  }
`;

const LogsModal = ({ open, onClose, selectedPatient, selectedStudyId }) => {
  const [allLogs, setAllLogs] = useState([]);
  const [loading, setLoading] = useState(true);

  const fetchLogs = useCallback(async () => {
    try {
      const requestsResponse = await api.get(
        `${GetStudyLogs}/${selectedStudyId}`
      );
      console.log("Requests:", requestsResponse);
      setAllLogs(requestsResponse.data || []);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }, [selectedStudyId]);

  useEffect(() => {
    if (open) {
      setLoading(true);
      fetchLogs();
    }
  }, [open, fetchLogs]);

  return (
    <>
      <Dialog
        open={open}
        onClose={onClose}
        fullWidth
        maxWidth={false}
        PaperProps={{
          sx: { width: "80vw", maxHeight: "80vh" },
        }}
      >
        <ModalHeader
          title={`All Logs for: ${selectedPatient.first_name} ${selectedPatient.last_name}`}
          onClose={onClose}
        />
        <Content>
          <StyledTableContainer component={Paper}>
            {loading ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  padding: "20px",
                  backgroundColor: "#2B3342",
                }}
              >
                <CircularProgress style={{ color: "#FBFEFF" }} />
              </div>
            ) : (
              <>
                <Table stickyHeader>
                  <TableHead>
                    <TableRow>
                      <HeaderStyledTableCell>#</HeaderStyledTableCell>
                      <HeaderStyledTableCell>
                        Destination AET
                      </HeaderStyledTableCell>
                      <HeaderStyledTableCell>Status</HeaderStyledTableCell>
                      <HeaderStyledTableCell>
                        Destination IP
                      </HeaderStyledTableCell>
                      <HeaderStyledTableCell>
                        Destination Port
                      </HeaderStyledTableCell>
                      <HeaderStyledTableCell>
                        Created Date
                      </HeaderStyledTableCell>
                      <HeaderStyledTableCell>Results</HeaderStyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {allLogs && allLogs.length > 0 ? (
                      allLogs.map((log, index) => (
                        <TableRow
                          key={log.request_uuid}
                          style={{ cursor: "pointer" }}
                        >
                          <StyledTableCell>{index + 1}</StyledTableCell>
                          <StyledTableCell>
                            {log.destinationAET || "N/A"}
                          </StyledTableCell>
                          <StyledTableCell>
                            {log.status || "N/A"}
                          </StyledTableCell>
                          <StyledTableCell>
                            {log.destinationIP || "N/A"}
                          </StyledTableCell>
                          <StyledTableCell>
                            {log.destinationPort || "N/A"}
                          </StyledTableCell>
                          <StyledTableCell>
                            {log.created_at || "N/A"}
                          </StyledTableCell>
                          <StyledTableCell>
                            {log.results === true ? "true" : "false"}
                          </StyledTableCell>
                        </TableRow>
                      ))
                    ) : (
                      <TableRow>
                        <StyledTableCell
                          colSpan={8}
                          style={{ textAlign: "center" }}
                        >
                          No logs found
                        </StyledTableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </>
            )}
          </StyledTableContainer>
        </Content>
        <DialogActionsStyled>
          <Button color="secondary" variant="contained" onClick={onClose}>
            Close
          </Button>
        </DialogActionsStyled>
      </Dialog>
    </>
  );
};

export default LogsModal;
