import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  MenuItem,
  FormControlLabel,
  TextField,
  Backdrop,
  CircularProgress,
} from "@mui/material";
import styled from "styled-components";

import { makeStyles } from "@mui/styles";
import StyledTextField from "./StyledTextField";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import IOSSwitch from "./IOSSwitch";
import { AddPatient } from "../../api/Patient";
import api from "../../api/config";
import Notification from "./Notification";
import ModalHeader from "./ModalHeader";

const Content = styled(DialogContent)`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 16px;
  background-color: #2b3342;
  color: white;
`;

const Row = styled.div`
  display: flex;
  gap: 16px;
`;

const TextArea = styled(StyledTextField)`
  height: 100px;
  textarea {
    height: 80px;
  }
`;

const Footer = styled(DialogActions)`
  background-color: #28929c;
  padding: 10px;
  font-size: 14px;
`;

const useStyles = makeStyles({
  select: {
    "&:before": {
      borderColor: "white",
    },
    "&:after": {
      borderColor: "white",
    },
    "&:not(.Mui-disabled):hover::before": {
      borderColor: "white",
    },
  },
  icon: {
    fill: "white",
  },
  root: {
    color: "white",
  },
  label: {
    color: "white",
    "&.Mui-focused": {
      color: "white",
    },
  },
});

const NewPatientModal = ({
  open,
  onClose,
  patientClone = null,
  origin = "default",
}) => {
  const [legalCase, setLegalCase] = useState(false);
  const [mamoCase, setMamoCase] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [middleName, setMiddleName] = useState("");
  const [lastName, setLastName] = useState("");
  const [privateNotes, setPrivateNotes] = useState("");
  const [gender, setGender] = useState("male");
  const [birthDate, setBirthDate] = useState(dayjs());
  const [street, setStreet] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [zipCode, setZipCode] = useState("");
  const [id, setId] = useState("");
  const [loading, setLoading] = useState(false);
  const classes = useStyles();
  const [addedPatient, setAddedPatient] = useState({});
  const [notification, setNotification] = useState({
    open: false,
    message: "",
    severity: "success",
  });
  const handleToggleChange = (type, value) => {
    if (type === "legal") setLegalCase(value);
    if (type === "mamo") setMamoCase(value);
  };

  useEffect(() => {
    if (open && patientClone) {
      setId(patientClone.client_patient_uuid || "");
      setFirstName(patientClone.first_name ?? "");
      setMiddleName(patientClone.middle_name ?? "");
      setLastName(patientClone.last_name ?? "");
      setGender(patientClone.gender ?? "male");
      setStreet(patientClone.address_street ?? "");
      setCity(patientClone.address_city ?? "");
      setState(patientClone.address_state ?? "");
      setZipCode(patientClone.address_zip ?? "");
      setLegalCase(patientClone.legal_case ?? false);
      setMamoCase(patientClone.mamo_case ?? false);

      if (patientClone.date_of_birth) {
        setBirthDate(dayjs(patientClone.date_of_birth));
      } else {
        setBirthDate(dayjs());
      }
      setPrivateNotes(patientClone.notes ?? "");
    } else if (open && !patientClone) {
      setId("");
      setFirstName("");
      setMiddleName("");
      setLastName("");
      setGender("male");
      setStreet("");
      setCity("");
      setState("");
      setZipCode("");
      setLegalCase(false);
      setMamoCase(false);
      setBirthDate(dayjs());
      setPrivateNotes("");
    }
  }, [open, patientClone]);

  const handleSave = async () => {
    const saveObject = {
      client_patient_uuid: id,
      first_name: firstName,
      middle_name: middleName,
      last_name: lastName,
      notes: privateNotes,
      gender,
      date_of_birth: birthDate.format("YYYY-MM-DD"),
      address_street: street,
      address_city: city,
      address_state: state,
      address_zip: zipCode,
      legal_case: legalCase,
      mamo_case: mamoCase,
    };

    setLoading(true);
    try {
      let clientuuid = localStorage.getItem(`clientuuid`);
      const response = await api.post(
        `${AddPatient}/${clientuuid}`,
        saveObject
      );

      console.log("Patient created successfully:", response.data);
      setAddedPatient(response.data);

      setNotification({
        open: true,
        message: "Patient Created Successfully",
        severity: "success",
      });

      setLoading(false);
      setTimeout(() => {
        onClose();
      }, 2000);
    } catch (error) {
      console.error("Error updating patient:", error);
      setNotification({
        open: true,
        message: "Failed To Save Patient",
        severity: "error",
      });
      setLoading(false);
    }
  };

  const handleCloseNotification = () => {
    setNotification({ ...notification, open: false });
  };

  const sanitizeText = (text) => {
    return text ? text.replace(/\s+/g, "") : "";
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <ModalHeader title="New Patient" onClose={onClose} />
      <Content>
        <Row>
          <StyledTextField
            label="ID"
            variant="outlined"
            fullWidth
            value={id}
            onChange={(e) => setId(sanitizeText(e.target.value))}
          />
        </Row>

        <Row>
          <StyledTextField
            label="First Name"
            variant="outlined"
            fullWidth
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
          />
          <StyledTextField
            label="Middle Name"
            variant="outlined"
            fullWidth
            value={middleName}
            onChange={(e) => setMiddleName(e.target.value)}
          />
          <StyledTextField
            label="Last Name"
            variant="outlined"
            fullWidth
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
          />
        </Row>

        <StyledTextField
          select
          className={classes.select}
          label="Gender"
          variant="outlined"
          fullWidth
          value={gender}
          onChange={(e) => setGender(e.target.value)}
          SelectProps={{
            MenuProps: {
              PaperProps: {
                sx: {
                  backgroundColor: "#141b25",
                  color: "#FBFEFF",
                  maxHeight: 300,
                },
              },
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "left",
              },
              transformOrigin: {
                vertical: "top",
                horizontal: "left",
              },
            },
          }}
          sx={{
            color: "white",
            ".MuiOutlinedInput-notchedOutline": {
              borderColor: "#ffffff",
              borderRadius: "12px",
            },
            "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
              borderColor: "#ffffff",
              borderRadius: "12px",
            },
            "&:hover .MuiOutlinedInput-notchedOutline": {
              borderColor: "#ffffff",
              borderRadius: "12px",
            },
            ".MuiSvgIcon-root ": {
              fill: "white !important",
            },
          }}
        >
          <MenuItem value="male">Male</MenuItem>
          <MenuItem value="female">Female</MenuItem>
          <MenuItem value="other">Other</MenuItem>
        </StyledTextField>

        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            label="Date of Birth"
            value={birthDate}
            onChange={(newValue) => setBirthDate(newValue)}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Date of Birth"
                variant="outlined"
                fullWidth
                sx={{ backgroundColor: "black" }}
              />
            )}
            slotProps={{
              layout: {
                sx: {
                  color: "#000000",
                  backgroundColor: "#3E526A",
                },
              },
            }}
            sx={{
              width: "100%",
              "& .MuiPickersLayout-root": {
                backgroundColor: "#141b25 !important",
              },
              "& .MuiInputLabel-root": {
                color: "#ffffff",
                borderRadius: "12px",
                borderColor: "#ffffff",
              },
              "& .MuiInputLabel-root.Mui-focused": {
                color: "#ffffff",
                borderColor: "#F0F5F7",
              },
              "& .MuiOutlinedInput-root": {
                "& > fieldset": { borderColor: "#ffffff" },
                "&:hover > fieldset": { borderColor: "#ffffff" },
                "&.Mui-focused > fieldset": { borderColor: "#ffffff" },
              },
              "& .MuiSvgIcon-root": {
                fill: "white !important",
              },
              "& .MuiPaper-root": {
                backgroundColor: "#141b25",
              },
            }}
          />
        </LocalizationProvider>

        <StyledTextField
          label="Street"
          variant="outlined"
          fullWidth
          value={street}
          onChange={(e) => setStreet(e.target.value)}
        />

        <Row>
          <StyledTextField
            label="City"
            variant="outlined"
            fullWidth
            value={city}
            onChange={(e) => setCity(e.target.value)}
          />
          <StyledTextField
            label="State"
            variant="outlined"
            fullWidth
            value={state}
            onChange={(e) => setState(e.target.value)}
          />
          <StyledTextField
            label="Zip Code"
            variant="outlined"
            fullWidth
            value={zipCode}
            onChange={(e) => setZipCode(sanitizeText(e.target.value))}
          />
        </Row>
        <Row>
          <TextArea
            label="Private Notes"
            variant="outlined"
            fullWidth
            multiline
            rows={4}
            value={privateNotes}
            onChange={(e) => setPrivateNotes(e.target.value)}
          />
        </Row>
      </Content>
      <Footer>
        <DialogActions>
          <Button onClick={handleSave} color="primary" variant="contained">
            Save
          </Button>
        </DialogActions>
      </Footer>
      <Backdrop open={loading} style={{ zIndex: 1300, color: "#fff" }}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Notification
        open={notification.open}
        message={notification.message}
        severity={notification.severity}
        handleClose={handleCloseNotification}
      />
    </Dialog>
  );
};

export default NewPatientModal;
