import React from "react";
import { IconButton, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import styled from "styled-components";
import { DialogTitle } from "@mui/material";

const Header = styled(DialogTitle)`
  color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
`;

export default function ModalHeader({ title, onClose }) {
  return (
    <div style={{ backgroundColor: "#2b3342" }}>
      <Header>
        <Typography variant="h6" className="text-blue-400">
          {title}
        </Typography>
        <IconButton onClick={onClose} color="inherit" size="small">
          <CloseIcon />
        </IconButton>
      </Header>
      <hr style={{ borderColor: "#A1A5AC", margin: "0 16px" }} />
    </div>
  );
}
